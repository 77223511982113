/* STYLING for DEKSTOP VIEW */
@media (min-width: 768px) {
  /* ================= */
  /* HEADER */
  /* ================= */
  header {
    width: 25vw;
    height: 100vh;
    background-color: #005d66;
    /* opacity: 75%; */
  }
  header img {
    border: 2px solid orangered;
  }
  /* ================= */
  /* AKHIR HEADER */
  /* ================= */

  /* ================= */
  /* NAVBAR */
  /* ================= */
  nav {
    margin-left: 25vw;
    height: 4rem;
    /* height: 10vh; */
    background-color: #09707a;
  }
  nav .navbar {
    height: 100%;
  }
  nav .nav-link {
    cursor: pointer;
  }
  nav .nav-link:hover {
    border-bottom: 2px solid orange;
  }
  nav .active {
    border-bottom: 2px solid orangered;
  }
  /* ================= */
  /* AKHIR NAVBAR */
  /* ================= */

  /* ================= */
  /* MAIN */
  /* ================= */
  main {
    margin-left: 25vw;
    margin-top: 4rem;
    /* margin-top: 10vh; */
  }
  .judulHalaman {
    color: #005d66;
    text-shadow: 2px 2px 7px black;
  }
  .education-img {
    width: 250px;
    height: 250px;
  }
  #contact .follow-me i:hover {
    color: #005d66;
  }
  .portfolio-modal {
    width: 75vw !important;
    max-width: none !important;
    height: 90vh !important;
    background-color: transparent !important;
  }
  .portfolio-modal .modal-content {
    max-height: 90% !important;
    background-color: transparent !important;
  }

  /* ================= */
  /* AKHIR MAIN */
  /* ================= */
}

/* STYLING for MOBILE VIEW */
@media (max-width: 768px) {
  /* ================= */
  /* HEADER */
  /* ================= */
  header {
    display: none;
    width: 25vw;
    height: 100vh;
    background-color: #005d66;
    /* opacity: 75%; */
  }
  header img {
    border: 2px solid #f9a01b;
  }
  /* ================= */
  /* AKHIR HEADER */
  /* ================= */

  /* ================= */
  /* NAVBAR */
  /* ================= */
  nav {
    /* margin-left: 25vw; */
    height: 3.5rem;
    /* height: 10vh; */
    background-color: #09707a;
  }
  nav .nav-link {
    cursor: pointer;
  }
  /* ================= */
  /* AKHIR NAVBAR */
  /* ================= */

  /* ================= */
  /* MAIN */
  /* ================= */
  main {
    /* margin-left: 25vw; */
    margin-bottom: 3.5rem;
    /* margin-bottom: 10vh; */
  }
  #intro p {
    text-align: justify;
  }
  .judulHalaman {
    color: #005d66;
    text-shadow: 0px 0px 3px black;
  }
  .education-img {
    width: 250px;
    height: 250px;
  }
  #contact h1 {
    text-align: center;
  }
  /* .portfolio-modal {
    width: 90vw !important;
    max-width: none !important;
    height: 90vh !important;
    background-color: transparent !important;
  } */
  .portfolio-modal .modal-content {
    /* max-height: 90% !important; */
    background-color: transparent !important;
  }
  /* ================= */
  /* AKHIR MAIN */
  /* ================= */
}
